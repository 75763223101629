@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "din-condensed";
  font-weight: 800;
  src: url("/assets/fonts/din-condensed-bold.ttf") format("truetype");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Medium";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/ITCAvantGardeStdMd.woff") format("woff");
}

.DraftEditor-root h1 {
  @apply text-2xl;
}

.DraftEditor-root h2 {
  @apply text-xl;
}

.DraftEditor-root h3 {
  @apply text-lg;
}

.DraftEditor-root blockquote {
  @apply pl-2 border-l-4 border-blue-300 bg-gray-100 rounded;
}

.public-DraftStyleDefault-block {
  @apply mt-4;
}

/*
POST LAYOUT
*/
.post-layout {
  display: flex;
  flex-flow: column;
}

.repeating-gradient {
  background: repeating-linear-gradient(
    90deg,
    hsla(152, 47%, 21%, 1),
    hsla(152, 47%, 21%, 1) 10px,
    hsl(152.7, 22.1%, 48.8%) 10px,
    hsl(152.7, 22.1%, 48.8%) 20px
  );
}

.prose p {
  @apply mt-2;
}

@media (min-width: 640px) {
  .post-layout {
    display: grid;
    grid-template-rows: min-content 1fr min-content min-content;
    grid-template-columns: 1fr min-content;
  }
}

@media (min-width: 1024px) {
  .post-layout {
    grid-template-columns: 1fr 2fr min-content;
  }
}

/*
VIDEO CONTAINER
*/

.video > iframe {
  width: 100%;
  height: 100%;
}

iframe {
  max-width: 100%;
}

.jitsi > iframe {
  width: 100%;
  min-height: 200px;
}

@media (min-width: 640px) {
  .jitsi > iframe {
    min-height: 400px;
  }
}

@media (min-width: 1024px) {
  .jitsi > iframe {
    min-height: 600px;
  }
}

@layer components {
  .header {
    @apply uppercase tracking-wide font-header;
  }
}
